import React, { useContext, useEffect } from "react";
import { AuthContext } from "@fireactjs/core";
import { Outlet } from "react-router-dom";
import AuthAlertAPI from "authalert";

/** detect if authUser is updated and call the AuthAlert API */
export const UserStatus = () => {
    const authUser = useContext(AuthContext);

    useEffect(() => {
        if (authUser.authUser && authUser.authUser.user && authUser.authUser.user.uid) {
            // check if the API has been called before and skip if it has
            if (localStorage.getItem("authalert_called_"+authUser.authUser.user.uid)) {
                return;
            }
            localStorage.setItem("authalert_called_"+authUser.authUser.user.uid, "true");
            // call the AuthAlert API here
            AuthAlertAPI("6QUdeik9f2hLSrNDOwGy", {
                user_id: authUser.authUser.user.uid,
                email: authUser.authUser.user.email,
                first_name: authUser.authUser.user.displayName
            }, (response) => {
                //console.log(response);
            }, (error) => {
                //console.error(error);
            });
        }
    },[authUser]);

    return (
        <Outlet />
    )
}