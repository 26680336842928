import { AuthContext, SetPageTitle } from "@fireactjs/core";
import { SubscriptionContext } from "@fireactjs/saas";
import { Alert, Box, Button, Container, Paper, Stack, Typography, Grid, TextField } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import Chart from "react-google-charts";
import { doc, setDoc } from 'firebase/firestore';

export const Dashboard = () => {
    const { subscription, setSubscription } = useContext(SubscriptionContext);
    const { firestoreInstance } = useContext(AuthContext);
    const [ usage, setUsage ] = React.useState([]);

    const [ budgetLimit, setBudgetLimit ] = React.useState(subscription.quota?Intl.NumberFormat().format(subscription.quota):0);
    const [ processing, setProcessing ] = React.useState(false);
    const [ processSuccess, setProcessSuccess ] = React.useState(null);

    // read the usage collection from the subscription object in firestore
    useEffect(() => {
        // define usage collection
        const usageCollection = collection(firestoreInstance, "subscriptions", subscription.id, "usage");
        // read usage collection
        getDocs(usageCollection).then((querySnapshot) => {
            let usageData = [];
            querySnapshot.forEach((doc) => {
                usageData.push([doc.id, doc.data().requests]);
            });
            if(usageData.length>0){
                // sort usageData by period
                usageData.sort((a, b) => {
                    if(a[0] < b[0]){
                        return -1;
                    }
                    if(a[0] > b[0]){
                        return 1;
                    }
                    return 0;
                });
                let formatedUsageData = [];
                formatedUsageData.push(["Period", "Requests"]);
                let index = 0;
                for (let data of usageData) {
                    // convert timestamp to date string
                    const periodStart = new Date(parseInt(data[0]) * 1000).toLocaleDateString();
                    const periodEnd = usageData[index+1] ? new Date(parseInt(usageData[index+1][0]) * 1000).toLocaleDateString() : "Now";
                    const requests = data[1];
                    formatedUsageData.push([periodStart + " to " + periodEnd, requests]);
                    index++;
                }
                // set usage state
                setUsage(formatedUsageData);
            }
        });
    }, [subscription, firestoreInstance]);

    return (
        <Container maxWidth="lg">
            <SetPageTitle title={"Dashboard"+(subscription.name!==""?(" - "+subscription.name):"")} />
            <Stack spacing={2}>
                <Paper>
                    <Box p={2}>
                        <Typography component="h1" variant="h4">Usage Dashboard</Typography>
                        <Typography p={1}>View your usage data in the chart. The usage data is updated hourly.</Typography>
                    </Box>
                    <Box p={2}>
                        {usage.length>0 &&
                            <Chart chartType="ColumnChart" width={"100%"} height={"500px"} data={usage} />
                        }
                        {usage.length===0 &&
                            <Typography p={1}>No usage data recorded. Please see the installation instructions.</Typography>
                        }
                    </Box>
                </Paper>
                <Paper>
                    <Box p={2}>
                        <Typography component="h1" variant="h4">Budget</Typography>
                        {subscription.planId !== "developer" && 
                            <>
                                {processSuccess === true && <Alert severity="success"  onClose={() => {setProcessSuccess(null)}}>Budget updated successfully.</Alert>}
                                {processSuccess === false && <Alert severity="error"  onClose={() => {setProcessSuccess(null)}}>Something went wrong. Try again later.</Alert>}
                                <Typography p={1}>Email alerts will be sent when usage reaches 50%, 75%, 90%, and 100%. Set the budget to 0 if you prefer not to receive alerts. Please note, the budget will not limit or stop the API service if usage exceeds the set amount.</Typography>
                                <Grid container spacing={2} mt={1}>
                                    <Grid item md={2}>
                                        <TextField size="small" variant="outlined" InputProps={{
                                                inputMode: 'numeric', // Mobile optimization for numeric input
                                            }}
                                            fullWidth onChange={(e) => {
                                                const inputValue = e.target.value;
                                                // Remove all non-numeric characters (excluding commas)
                                                const onlyNums = inputValue.replace(/[^0-9]/g, '');

                                                // Format the number with commas
                                                const formattedValue = new Intl.NumberFormat().format(onlyNums);

                                                setBudgetLimit(formattedValue);    
                                            }} value={budgetLimit} />
                                    </Grid>
                                    <Grid item md={10} alignItems={"stretch"} style={{display: "flex", flexDirection: 'column', justifyContent: 'center'}}>
                                        requests per month
                                    </Grid>
                                    <Grid item md={12}>
                                        <Button variant="contained" disabled={processing} color="primary" onClick={() => {
                                            setProcessing(true);
                                            setProcessSuccess(null);
                                            // update the subscription object in firestore
                                            var quota = parseInt(budgetLimit.replace(/,/g, '').replace(/\./g, ''));
                                            if(isNaN(quota)){
                                                quota = 0;
                                            }
                                            // save the subscription to firestore
                                            const docRef = doc(firestoreInstance, "subscriptions", subscription.id);
                                            setDoc(docRef, {quota: quota}, {merge: true}).then(() => {
                                                setSubscription({...subscription, quota: quota});
                                                setProcessSuccess(true);
                                            }).catch((error) => {
                                                setProcessSuccess(false);
                                            }).finally(() => {
                                                setProcessing(false);
                                            });
                                            
                                        }  }>Save</Button>
                                    </Grid>
                                </Grid>
                                
                            </>
                        }
                        {subscription.planId === "developer" && 
                            <Typography p={1}>The {subscription.plan} plan does not support custom budget settings and is limited to 1,000 API calls per month. Usage alerts will be sent to the project owner via email when 50%, 75%, 90%, and 100% of the limit is reached. To remove this limitation and enjoy unlimited API calls, consider upgrading to one of our higher-tier plans.</Typography>
                        }
                   </Box>
                </Paper>
            </Stack>
        </Container>
    )
}