import React, { useContext, useState } from "react";
import { SubscriptionContext } from "@fireactjs/saas";
import { Alert, Box, Button, Container, Divider, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Paper, Switch, TextField, Typography } from "@mui/material";
import { AuthContext, SetPageTitle } from "@fireactjs/core";
import { FireactContext } from "@fireactjs/core";
import { doc, setDoc } from 'firebase/firestore';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export const Settings = () => {
    const { subscription, setSubscription } = useContext(SubscriptionContext);
    const [ inputSubscripton, setInputSubscription ] = useState({
        name: subscription.name || "",
        domainAuthEnabled: subscription.domainAuthEnabled || false,
        domains: (subscription.domains?subscription.domains.join("\n"):""),
        tokenAuthEnabled: subscription.tokenAuthEnabled || false,
        token: subscription.token || "",
        webhookEnabled: subscription.webhookEnabled || false,
        webhookUrl: subscription.webhookUrl || "",
        webhookSecret: subscription.webhookSecret || "",
        mailgunEnabled: subscription.mailgunEnabled || false,
        mailgunDomain: subscription.mailgunDomain || "",
        mailgunApiKey: subscription.mailgunApiKey || "",
        mailgunSender: subscription.mailgunSender || "",
        mailgunSubject: subscription.mailgunSubject || "",
        mailgunTemplate: subscription.mailgunTemplate || ""
    });
    const [ error, setError ] = useState(null);
    const [ success, setSuccess ] = useState(null);
    const [ processing, setProcessing ] = useState(false);
    const { config } = useContext(FireactContext);
    const label = config.saas.subscription.singular.substr(0,1).toUpperCase() + config.saas.subscription.singular.substr(1);
    const { firestoreInstance } = useContext(AuthContext);

    const [ showMailgunKey, setShowMailgunKey ] = useState(false);
    const [ showWebhookSecret, setShowWebhookSecret] = useState(false);
    const [ showToken, setShowToken] = useState(false);

    const updateSubscription = () => {
        setProcessing(true);
        setError(null);
        setSuccess(null);
        var hasError = false;
        // check if either domain auth or token auth is enabled
        if(!inputSubscripton.domainAuthEnabled && !inputSubscripton.tokenAuthEnabled){
            hasError = true;
            setError("Either Domain Authentication or Token Authentication must be enabled. Otherwise, the endpoint will be not accessible.");
            setProcessing(false);
        }
        // check if project name is empty
        if(!hasError && inputSubscripton.name.trim() === ""){
            hasError = true;
            setError(label + " is a required field.");
            setProcessing(false);
        }
        // split the domains by new line and remove any extra spaces or empty lines
        const domains = inputSubscripton.domains.split("\n").map(domain => domain.trim()).filter(domain => domain !== "") || []
        console.log('Domains', domains);
        // check if domain auth is enabled and domains are empty
        if(!hasError && inputSubscripton.domainAuthEnabled && domains.length === 0){
            hasError = true;
            setError("Allowed Domains is a required field.");
            setProcessing(false);
        }
        // check if domains are in valid format
        if(!hasError && inputSubscripton.domainAuthEnabled){
            // split the domains by new line and remove any extra spaces or empty lines
            domains.forEach(domain => {
                // check if is not in valid format and not "localhost"
                if(!domain.match(/^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/) && domain !== "localhost"){
                    hasError = true;
                    setError("Allowed Domains must be in valid format. Example: example.com");
                    setProcessing(false);
                }
            });
        }
        // check if token auth is enabled and token is empty
        if(!hasError && inputSubscripton.tokenAuthEnabled && inputSubscripton.token.trim() === ""){
            hasError = true;
            setError("Token is a required field.");
            setProcessing(false);
        }
        // check if webhook is enabled and webhook url is empty
        if(!hasError && inputSubscripton.webhookEnabled && inputSubscripton.webhookUrl.trim() === ""){
            hasError = true;
            setError("Webhook URL is a required field.");
            setProcessing(false);
        }
        // check if webhook is enabled and webhook secret is empty
        if(!hasError && inputSubscripton.webhookEnabled && inputSubscripton.webhookSecret.trim() === ""){
            hasError = true;
            setError("Webhook Secret is a required field.");
            setProcessing(false);
        }
        // check if mailgun is enabled and mailgun domain is empty
        if(!hasError && inputSubscripton.mailgunEnabled && inputSubscripton.mailgunDomain.trim() === ""){
            hasError = true;
            setError("Mailgun Domain is a required field.");
            setProcessing(false);
        }
        // check if mailgun is enabled and mailgun api key is empty
        if(!hasError && inputSubscripton.mailgunEnabled && inputSubscripton.mailgunApiKey.trim() === ""){
            hasError = true;
            setError("Mailgun API Key is a required field.");
            setProcessing(false);
        }
        // check if mailgun is enabled and mailgun sender is empty
        if(!hasError && inputSubscripton.mailgunEnabled && inputSubscripton.mailgunSender.trim() === ""){
            hasError = true;
            setError("Sender Email is a required field.");
            setProcessing(false);
        }
        // check if mailgun is enabled and mailgun subject is empty
        if(!hasError && inputSubscripton.mailgunEnabled && inputSubscripton.mailgunSubject.trim() === ""){
            hasError = true;
            setError("Notification Subject is a required field.");
            setProcessing(false);
        }
        // check if mailgun is enabled and mailgun template is empty
        if(!hasError && inputSubscripton.mailgunEnabled && inputSubscripton.mailgunTemplate.trim() === ""){
            hasError = true;
            setError("Notification Template is a required field.");
            setProcessing(false);
        }

        if(!hasError){
            console.log("input domains", domains);
            const docRef = doc(firestoreInstance, "subscriptions", subscription.id);
            setDoc(docRef, {
                name: inputSubscripton.name,
                domainAuthEnabled: inputSubscripton.domainAuthEnabled,
                domains: domains,
                tokenAuthEnabled: inputSubscripton.tokenAuthEnabled,
                token: inputSubscripton.token,
                webhookEnabled: inputSubscripton.webhookEnabled,
                webhookUrl: inputSubscripton.webhookUrl,
                webhookSecret: inputSubscripton.webhookSecret,
                mailgunEnabled: inputSubscripton.mailgunEnabled,
                mailgunDomain: inputSubscripton.mailgunDomain,
                mailgunApiKey: inputSubscripton.mailgunApiKey,
                mailgunSender: inputSubscripton.mailgunSender,
                mailgunSubject: inputSubscripton.mailgunSubject,
                mailgunTemplate: inputSubscripton.mailgunTemplate
            }, {merge: true}).then(() => {
                setSuccess('The settings have been successfully updated.');
                setProcessing(false);
                setSubscription(prevState => ({
                    ...prevState,
                    name: inputSubscripton.name,
                    domainAuthEnabled: inputSubscripton.domainAuthEnabled,
                    domains: domains,
                    tokenAuthEnabled: inputSubscripton.tokenAuthEnabled,
                    token: inputSubscripton.token,
                    webhookEnabled: inputSubscripton.webhookEnabled,
                    webhookUrl: inputSubscripton.webhookUrl,
                    webhookSecret: inputSubscripton.webhookSecret,
                    mailgunEnabled: inputSubscripton.mailgunEnabled,
                    mailgunDomain: inputSubscripton.mailgunDomain,
                    mailgunApiKey: inputSubscripton.mailgunApiKey,
                    mailgunSender: inputSubscripton.mailgunSender,
                    mailgunSubject: inputSubscripton.mailgunSubject,
                    mailgunTemplate: inputSubscripton.mailgunTemplate
                 }));
            }).catch(error => {
                setError(error.message);
                setProcessing(false);
            })
        }
    }

    return (
        <Container maxWidth="md">
            <SetPageTitle title={"Settings"+(inputSubscripton.name!==""?(" - "+inputSubscripton.name):"")} />
            <Paper>
                <Box p={2}>
                    <Typography component="h1" variant="h4" align="center">Settings</Typography>
                </Box>
                {error !== null &&
                    <Box p={2}>
                        <Alert severity="error">{error}</Alert>
                    </Box>
                }
                {success !== null &&
                    <Box p={2}>
                        <Alert severity="success">{success}</Alert>
                    </Box>
                }
                <Divider />
                <Box p={2}>
                    <TextField required fullWidth name="title" label={label} type="text" margin="normal" defaultValue={inputSubscripton.name} onChange={(e) => setInputSubscription(
                        prevState => ({
                            ...prevState,
                            name: e.target.value
                        })
                    )} />
                </Box>
                <Divider />
                <Box p={2}>
                    <FormGroup>
                        <FormControlLabel control={<Switch checked={inputSubscripton.domainAuthEnabled} onChange={(e) => setInputSubscription(prevState => ({
                            ...prevState,
                            domainAuthEnabled: !prevState.domainAuthEnabled
                        }))} />} label="Domain Authentication" />
                        <Typography variant="caption" color="textSecondary">This feature allows you to restrict access to your API endpoint to specific domains.</Typography>
                        {inputSubscripton.domainAuthEnabled &&   
                            <TextField sx={{m:1}} fullWidth name="domains" label="Allowed Domains" multiline rows={5} type="text" margin="normal" value={inputSubscripton.domains} onChange={(e) =>
                                setInputSubscription(prevState => ({
                                    ...prevState,
                                    domains: e.target.value
                                })
                            )} />
                        }
                    </FormGroup>
                </Box>
                <Divider />
                <Box p={2}>
                    <FormGroup>
                        <FormControlLabel control={<Switch checked={inputSubscripton.tokenAuthEnabled} onChange={(e) => setInputSubscription(prevState => ({
                            ...prevState,
                            tokenAuthEnabled: !prevState.tokenAuthEnabled
                        }))} />} label="Token Authentication" />
                        <Typography variant="caption" color="textSecondary">This feature allows you to restrict access to your API endpoint with an authentication token.</Typography>
                        {inputSubscripton.tokenAuthEnabled &&   
                            <FormControl fullWidth sx={{m:1}} variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-token">Token</InputLabel>
                                <OutlinedInput 
                                    id="outlined-adornment-token"
                                    name="token" type={showToken?'text':'password'}
                                    value={inputSubscripton.token}
                                    onChange={(e) => setInputSubscription(prevState => ({
                                        ...prevState,
                                        token: e.target.value
                                    }))}
                                    endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowToken(!showToken)}
                                            edge="end"
                                        >
                                            {showToken?<VisibilityOff />:<Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                    }
                                    label="Token"
                                />
                            </FormControl>
                        }
                    </FormGroup>
                </Box>
                <Divider />
                <Box p={2}>
                    <FormGroup>
                        <FormControlLabel control={<Switch checked={inputSubscripton.webhookEnabled} onChange={(e) => setInputSubscription(prevState => ({
                            ...prevState,
                            webhookEnabled: !prevState.webhookEnabled
                        }))} />} label="Webhook Integration" />
                        <Typography variant="caption" color="textSecondary">This feature allows you send the new device detection data to a webhook.</Typography>
                        {inputSubscripton.webhookEnabled &&   
                            <>
                                <TextField sx={{m:1}} fullWidth name="webhookUrl" label="Webhook URL" type="text" margin="normal" value={inputSubscripton.webhookUrl} onChange={(e) => setInputSubscription(prevState => ({
                                    ...prevState,
                                    webhookUrl: e.target.value
                                }))} />
                                <FormControl fullWidth sx={{m:1}} variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-webhook">Webhook Secret</InputLabel>
                                    <OutlinedInput 
                                        id="outlined-adornment-webhook"
                                        name="webhookSecret" type={showWebhookSecret?'text':'password'}
                                        value={inputSubscripton.webhookSecret}
                                        onChange={(e) => setInputSubscription(prevState => ({
                                            ...prevState,
                                            webhookSecret: e.target.value
                                        }))}
                                        endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowWebhookSecret(!showWebhookSecret)}
                                                edge="end"
                                            >
                                                {showWebhookSecret?<VisibilityOff />:<Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                        }
                                        label="Webhook Secret"
                                    />
                                </FormControl>
                            </>
                        }
                    </FormGroup>
                </Box>
                <Divider />
                <Box p={2}>
                    <FormGroup>
                        <FormControlLabel control={<Switch checked={inputSubscripton.mailgunEnabled} onChange={(e) => setInputSubscription(prevState => ({
                            ...prevState,
                            mailgunEnabled: !prevState.mailgunEnabled
                        }))} />} label="Mailgun Notification Integration" />
                        <Typography variant="caption" color="textSecondary">This feature allows you send the new device detection data to Mailgun to send a notification email to the user.</Typography>
                        {inputSubscripton.mailgunEnabled &&   
                            <>
                                <TextField sx={{m:1}} fullWidth name="mailgunDomain" label="Mailgun Domain" type="text" margin="normal"
                                    value={inputSubscripton.mailgunDomain}
                                    onChange={(e) => setInputSubscription(prevState => ({
                                        ...prevState,
                                        mailgunDomain: e.target.value
                                    }))}
                                />
                                <FormControl fullWidth sx={{m:1}} variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-mailgun">Mailgun API Key</InputLabel>
                                    <OutlinedInput 
                                        id="outlined-adornment-mailgun"
                                        name="mailgunApiKey" type={showMailgunKey?'text':'password'}
                                        value={inputSubscripton.mailgunApiKey}
                                        onChange={(e) => setInputSubscription(prevState => ({
                                            ...prevState,
                                            mailgunApiKey: e.target.value
                                        }))}
                                        endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowMailgunKey(!showMailgunKey)}
                                                edge="end"
                                            >
                                                {showMailgunKey?<VisibilityOff />:<Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                        }
                                        label="Mailgun API Key"
                                    />
                                </FormControl>
                                <TextField sx={{m:1}} fullWidth name="mailgunSender" label="Sender Email" type="text" margin="normal"
                                    value={inputSubscripton.mailgunSender}
                                    onChange={(e) => setInputSubscription(prevState => ({
                                        ...prevState,
                                        mailgunSender: e.target.value
                                    }))}
                                />
                                <TextField sx={{m:1}} fullWidth name="mailgunSubject" label="Notification Subject" type="text" margin="normal"
                                    value={inputSubscripton.mailgunSubject}
                                    onChange={(e) => setInputSubscription(prevState => ({
                                        ...prevState,
                                        mailgunSubject: e.target.value
                                    }))}
                                />
                                <TextField sx={{m:1}} fullWidth name="mailgunTemplate" label="Notification Template" type="text" margin="normal"
                                    value={inputSubscripton.mailgunTemplate}
                                    onChange={(e) => setInputSubscription(prevState => ({
                                        ...prevState,
                                        mailgunTemplate: e.target.value
                                    }))}
                                />
                            </>
                        }
                    </FormGroup>
                </Box>
                <Divider />
                <Box p={2}>
                    <Grid container>
                        <Grid item xs>
                            
                        </Grid>
                        <Grid item>
                            <Button type="button" color="primary" variant="contained" disabled={processing} onClick={() => updateSubscription()} >Save</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
        </Container>
    )
}